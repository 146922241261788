.tes-mail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url('./img/bg.webp');
}

.tes-mail-logo {
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 5px;
  transition: .5s;
  background-color: white;
  border-bottom-right-radius: 10px;
  z-index: 1000;

  &:hover {
    filter: grayscale(1);
    cursor: pointer;
  }

  img {
    width: 100px;
    height: auto;
  }
}

.tes-mail-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  
  a {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #379cd7;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition: .5s;
    text-decoration: none;
    box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.5);
  }

  a:hover {
    background-color: #226191;
  }
}